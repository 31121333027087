import api from '@/services/api'
import version from '@/services/version'
import Vue from 'vue';

const SYNC_RUNNING = 2;
const SYNC_FINISH = 1;
const SYNC_WAITING = 0;

let defaultInter = {
	responses: {}, // => {form_id, action: {status, name}, reponse1, reponse2....}
	uid: null,
	sync: null,
	syncfinished: false,
	started_at: '',
	ended_at: '',
	geoloc: {latitude: null, longitude: null, accuracy: null},
}

const store = {
	namespaced: true,
	state: {
		interventions: {}
	},
	mutations: {
		setintervention(state, {uid, data}) {
			console.log('setintervention', uid, data);
			if (!state.interventions[uid]) {
				Vue.set(state.interventions, uid, JSON.parse(JSON.stringify({...defaultInter, ...{'uid':uid}, ...data})));
			} else {
				state.interventions[uid] = data
			}
		},
		setResponse(state, {uid, responseid, data}) {
			console.log('setResponse', uid, data);
			if (data.geoloc && data.geoloc.latitude) {
				state.interventions[uid].geoloc = data.geoloc;
			}
			state.interventions[uid].responses[responseid] = data;
		},
		addResponse(state, {uid, responseid, action, prefilled}) {
			console.log('addResponse commit', uid, action);
			if (prefilled === undefined){
				prefilled = {};
			}
			if (!state.interventions[uid]) {
				Vue.set(state.interventions, uid, JSON.parse(JSON.stringify({...defaultInter, ...{'uid':uid}})));
			}
			state.interventions[uid].responses[responseid] = {
				action: action,
				finished: false,
				geoloc: {latitude: null, longitude: null, accuracy: null},
				...prefilled
			}
		},
		removeResponse(state, {uid, responseid}) {
			let responses = {...state.interventions[uid].responses};
			delete responses[responseid];
			state.interventions[uid].responses = responses;
		},
		reset(state) {
			state.interventions = {};
		},
		changesync(state, {uid, sync}) {
			if (state.interventions[uid]){
				state.interventions[uid].sync = sync;
			}
		},
		remove(state, uid){
			let inter = {...state.interventions};
			delete inter[uid];
			state.interventions = inter;
		}
	},
	
	actions: {
		setIntervention: ({commit, state}, {uid, data}) => {
			commit('setintervention', {uid, data});
		},
		setResponse: ({commit, state}, {uid, responseid, data}) => {
			data.version = version.version;
			commit('setResponse', {uid, responseid, data});
		},
		addResponse: ({commit, state}, {uid, action, prefilled}) => {
			let responseid = Date.now();
			commit('addResponse', {uid,responseid, action, prefilled});
			return responseid;
		},
		removeResponse: ({commit, state}, {uid, responseid}) => {
			commit('removeResponse', {uid,responseid});
		},
		finishIntervention: async ({commit, state, dispatch}, {uid, etatid, start, end, finished}) => {
			if (etatid !== undefined || start !== undefined || end !== undefined || finished !== undefined) {
				let data = state.interventions[uid];
				if (!data) {
					data = JSON.parse(JSON.stringify({...defaultInter, ...{'uid':uid}}));
				}
				if (etatid) {
					data.toetatid = etatid;
				}
				if (finished) {
					data.syncfinished = finished;
				}
				if (start) {
					data.started_at = start;
				}
				if (end) {
					data.ended_at = end;
				}
				commit('setintervention', {uid, data});
			}
			try {
				commit('changesync', {uid:uid, sync: SYNC_RUNNING});
				let call = await api.post({
					url: 'intervention/v3/missions/' + uid + '/save',
					params: state.interventions[uid]
				})
				commit('changesync', {uid:uid, sync: SYNC_FINISH});
				dispatch('pictures/sync', {}, {root:true});
			} catch (err) {
				console.log('finishIntervention', err);
				commit('changesync', {uid:uid, sync: SYNC_WAITING});
			}
		},
		pushAll: ({commit, state, getters, dispatch}) => {
			getters.tosyncs.forEach((item) => {
				dispatch('finishIntervention', {uid: item.uid});
			})
		},
		reset: ({commit, state}) => {
			commit('reset');
		},
		
		async delete(context, uid) {
			console.log('delete inter', uid);
			context.commit('remove', uid);
		},
		
		async removeSynced(context, evtids){
			console.log('removeSynced interventions');
			let inter = context.state.interventions;
			if (Object.keys(inter).length){
				_.forEach(inter, (i,uid) => {
					if (i.sync == SYNC_FINISH && evtids.indexOf(uid) === -1) {
						context.commit('remove', uid);
					}
				})
			}
		},
	},
	getters: {
		getAll: (state) => {
			return state.interventions;
		},
		getIntervention: (state) => (uid) => {
			return state.interventions[uid] ? state.interventions[uid] : JSON.parse(JSON.stringify({...defaultInter, ...{'uid':uid}}));
		},
		getResponses: (state, getters) => ({uid, responseid}) => {
			let inter = getters.getIntervention(uid);
			return inter.responses[responseid] ? inter.responses[responseid] : {
				action: {status: '', name: ''}
			};
		},
		getSyncStatus: (state) => (uid) => {
			if (!state.interventions[uid]) {
				return null;
			}
			return state.interventions[uid].sync
		},
		getCountResponses: (state) => (uid) => {
			if (!state.interventions[uid]) {
				return 0;
			}
			return Object.keys(state.interventions[uid].responses).length;
		},
		hasRapportEnCours: (state) => (uid) => {
			if (!state.interventions[uid]) {
				return false;
			}
			
		},
		tosyncs: (state) => {
			return Object.values(state.interventions).filter((item) => {
				return item.sync == SYNC_WAITING;
			})
		}
		
	},
}

export default store